@import "../../../temas/variaveis";

#SecaoRodape {
  background-color: $cor-secundaria;
  color: white;

  .itemRodape {
    padding: 50px 0;

    .imgRodape {
      width: 100%;
      margin-bottom: 30px;
    }

    .colLink {
      .linha {
        border-top: 5px solid $cor-primaria;
        width: 130px;
        margin: 0;
        padding: 5px 0;
        opacity: 100%;
      }

      .itemTitulo {
        font-size: 20px;
        font-weight: bold;
      }

      a {
        text-decoration: none !important;
        color: white;

        p {
          font-size: 16px;
          margin-bottom: 2px;
        }
      }
    }

    .infoRodape {
      .icone {
        margin-bottom: 15px;
        color: $cor-primaria;
      }

      .itemTexto {
        margin-bottom: 15px;
        h3 {
          font-size: 16px;
          font-weight: bold;  
          margin-top: 0;
        }

        p {
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }

    .responsivo {
      display: none;
    }
  }

  .linhaCopyright {
    margin-top: 20px;
    border-top: 2px solid white;
    opacity: 100%;
  }

  .tytotech {
    display: flex;
    justify-content: end;
    .versao {
      margin-left: 5px;
    }
  }
}

@media (max-width: 991.98px) {
  #SecaoRodape {
    .desktop {
      display: none;
    }

    .responsivo {
      display: block !important;
      margin: 0;
    }

    .itemRodape {
      padding: 20px 0;

      .imgRodape {
        text-align: center;

        .logo {
          width: 80%;
        }
      }
    }
    .linha {
      border-top: 5px solid $cor-primaria;
      width: 130px;
      margin: 0;
      padding: 5px 0;
      opacity: 100%;
    }
    .accordion {
      --bs-accordion-bg: $cor-secundaria;
      --bs-accordion-btn-color: white;

      .accordion-button:not(.collapsed) {
        color: white;
        background-color: $cor-secundaria;
        box-shadow: none;
        font-weight: bold;
      }

      .accordion-button {
        font-weight: bold !important;
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>") !important;
      }

      .accordion-button::after {
        background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>") !important;
      }

      .accordion-item {
        background-color: $cor-secundaria;
        border-radius: 0;
        border-color: $cor-secundaria;
        color: $cor-primaria;
        font-size: 15px;

        a {
          text-decoration: none !important;
        }

        .icon {
          color: $cor-primaria;
        }
      }

      .menuResponsivo {

        .h5,
        h5 {
          color: white;
          margin-bottom: 15px;
          margin-top: 15px;
          text-transform: uppercase;
        }


        a {
          text-decoration: none;
          color: white;
        }
      }

      .infoRodape {
        .icone {
          margin-bottom: 15px;
        }

        .itemTexto {
          color: white;
        }
      }
    }
    .tytotech {
      justify-content: center;
      margin-top:15px;
    }
  }
}