@import "../../../temas/variaveis";

#Cabecalho {
  .navItem {
    margin-left: $margin-itens / 1.5;
    font-size: $tamanho-p-desktop;

    [title^="E-commerce"] {
      display: flex;
      color: white !important;
      background-color: $cor-botao;
      height: 45px;
      width: 147px;
      border-radius: 5px;
      span {
        display: table;
        margin: auto;
      }
    }

    .trabalhe-conosco {
      display: none;
    }

    .link {
      color: black;
      text-decoration: none;

      .textDropDown {
        border-left: 4px solid red;
        padding: 0 0;
        padding-left: 5px;
      }
    }

    .dropdown-menu {
      border: none;
      border-radius: 0;
      box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.12);
    }

    .botao {
      background-color: $cor-botao;
      border-color: $cor-botao;
      height: 45px;
      width: 150px;
    }

    .botao:hover {
      background-color: $cor-botao-hover;
      border-color: $cor-botao-hover;
    }

    .botao:active {
      background-color: $cor-botao-active;
      border-color: $cor-botao-active;
    }

    .botao:focus {
      background-color: $cor-botao-focus;
      border-color: $cor-botao-focus;
      box-shadow: none;
    }
  }
}

#SecaoCabecalho {
  padding: $margin-itens;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  position: relative;
  position: sticky;
  top: 0px;
  padding: 0;
  z-index: 1010;

  background-color: white;

  .loading {
    width: 295px;
    height: 70px;
  }

  .loadingMenu {
    width: 500px;
    height: 30px;
  }

  #Cabecalho {
    .cabecalhoBody {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1200px) {
  #SecaoCabecalho {
    .logo {
      height: 60px;
    }

    .loading {
      width: 200px;
    }
  }
}

@media (max-width: 991.98px) {
  #Cabecalho {
    .navItem {
      margin-right: $margin-itens/1.5;
      margin-bottom: $margin-itens / 2;
      .trabalhe-conosco {
        display: block;
      }
      .botao {
        width: 100%;
      }
    }
    .loadingMenu {
      margin-bottom: $margin-itens / 2;
      width: 100%;
      height: 250px;
    }
  }

  #SecaoCabecalho {
    .logo {
      height: 50px;
    }
  }
}

@media (max-width: 350px) {
  #SecaoCabecalho {
    .logo {
      height: 40px;
    }

    .loading {
      width: 150px;
    }
  }
}

.handler {
  border: none;
  background-color: transparent;
  margin-left: 14px;

  & > * {
    color: black;
    width: 25px;
    height: 25px;
  }
}
