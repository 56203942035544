.imagemViewerZoom {

  .img {
    height: 80vh;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .carousel.slide {
    width: 90vw;
  }

  .carousel-indicators {
    bottom: -50px;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-content {
    background-color: rgba($color: #000000, $alpha: 0.5);

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .closeModal {
      fill: whitesmoke;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1100;
      cursor: pointer;
    }

  }
}