@import "../../../temas/variaveis";

#SecaoUnidades {
  padding: $padding-page 0;

  .containerLoading {
    margin-bottom: $margin-itens;

    .textoLoading {
      height: $tamanho-p-desktop;
    }

    .selectLoading {
      height: 70px;
    }

    .cardLoading {
      height: 115px;
    }
  }

  .containerTexto {
    .texto {
      font-size: $tamanho-p-desktop;
    }
  }

  .botao {
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 300px;
    background: linear-gradient(120deg,
        $cor-botao-terciaria 70%,
        $cor-botao 30%);
    color: black;
    font-size: 18px;
    border: none;
    border-radius: 0;
  }

  .botao:hover {
    background: linear-gradient(120deg,
        $cor-botao-hover-terciaria 70%,
        $cor-botao 30%);
  }

  .botao:active {
    background: linear-gradient(120deg,
        $cor-botao-active-terciaria 70%,
        $cor-botao-active 30%);
  }

  .botao:focus {
    background-color: $cor-botao-focus;
    border-color: $cor-botao-focus;
    box-shadow: none;
  }

  .containerFiltro {
    margin-top: $margin-itens;

    .select {
      width: 100%;
      height: 50px;
      padding: 0 $margin-itens/2;
      font-weight: bold;
      font-size: 16px;
      border: 1px solid rgba(45, 45, 43, 0.27);
      margin-bottom: $margin-itens;
    }

    .select:focus-visible {
      outline: none;
    }
  }

  .headerExpandir:hover {
    .botao {
      background: linear-gradient(120deg,
          $cor-botao-hover-terciaria 70%,
          $cor-botao 30%);
    }
  }

  .headerExpandir:active {
    .botao {
      background: linear-gradient(120deg,
          $cor-botao-active-terciaria 70%,
          $cor-botao-active 30%);
    }
  }

  .headerExpandir:focus {
    .botao {
      background-color: $cor-botao-focus;
      border-color: $cor-botao-focus;
      box-shadow: none;
    }
  }

  .containerMapa {
    margin-bottom: 30px;
  }

  .accordion {

    .accordionItem {
      margin-bottom: $margin-itens;
      border: none;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

      .accordionHeader,
      :focus,
      :active,
      :visited,
      :active,
      :target {
        border: none;
        box-shadow: none;

        .accordion-button {
          background-color: white;
          color: black;
          border: none;
        }

        .icone {
          margin-right: $margin-itens/2;
        }

      }
    }

    .accordionBody {
      .containerImagem {
        align-items: center;
        justify-content: flex-start;
        display: flex;

        .imagem {
          height: 220px;
          width: 365px;
          object-fit: contain;
        }
      }

      .containerInformacao {
        display: flex;
        margin: $margin-itens/2 0;

        .containerEscrita {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          width: 100%;
          text-decoration: none;
          color: black;

          span {
            width: calc(100% - 40px);
            word-wrap: break-word;
          }

          .titulo {
            color: $cor-primaria;
            font-weight: bold;
          }
        }
      }
    }
  }

  .botaoPais {
    background-color: $cor-secundaria;
    box-shadow: none;
    border: none;
    margin: 10px;

    &:active,
    &:focus {
      background-color: $cor-botao;
    }

    &:hover {
      background-color: $cor-botao-hover;
    }
  }

  .active {
    background-color: $cor-botao;
  }

  .card {
    border: none;
    border-radius: 0;
    padding: $margin-itens;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin: $margin-itens 0;

    .cardHeader {
      background-color: white;
      border: none;

      .headerExpandir {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tituloIcone {
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;

          .icone {
            width: 45px;
          }

          .titulo {
            font-size: $tamanho-h3-desktop;
            margin: 0;
            margin-left: $margin-itens/2;
          }

          .subTitulo {
            font-size: $tamanho-p-desktop;
            margin: 0;
            padding: 0;
            margin-left: $margin-itens/2;
          }
        }
      }
    }

    .cardBody {
      .containerImagem {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .imagem {
          max-width: 365px;
        }
      }

      .titulo {
        font-size: $tamanho-h3-desktop;
        text-transform: uppercase;
        font-weight: bolder;
      }

      .containerInformacao {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: $margin-itens;

        &.servioAtendimento {
          display: block;
          margin-bottom: 0;

          .containerEscrita {
            margin: 0;
            text-decoration: none;
            color: black;

            span {
              font-size: $tamanho-h3-desktop;
              font-weight: bold;
              padding-top: 15px;
            }
          }
        }

        .icone {
          width: 40px;
        }

        .containerEscrita {
          margin-left: $margin-itens/2;
          width: 280px;
          text-decoration: none;
          color: black;

          .titulo {
            color: $cor-primaria;
            font-weight: bolder;
            font-size: $tamanho-p-desktop;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #SecaoUnidades {
    .accordion {

      .accordionItem {

        .accordionBody {
          .containerImagem {
            .imagem {
              width: 100%;
            }
          }
        }
      }
    }

    .card {
      padding: 15px 0;

      .cardHeader {
        .headerExpandir {
          flex-direction: column;
          align-items: flex-start;

          .tituloIcone {
            margin-bottom: $margin-itens;
            flex-direction: column;
            align-items: flex-start;

            .icone {
              margin-bottom: 30px;
            }

            .titulo,
            .subTitulo {
              margin-left: 0;
            }
          }

          .botao {
            width: 100%;
          }
        }
      }

      .cardBody {
        .containerImagem {
          margin-bottom: $margin-itens;

          .imagem {
            width: 100%;
          }
        }
      }
    }

    .servioAtendimento {
      margin-bottom: 30px !important;
    }
  }
}