@import "../../../temas/variaveis";

#SecaoCredenciados {
  margin-top: 80px;
  padding: $padding-page 0;
  background-color: #373435;

  .containerLoading {
    .imagemLoading {
      aspect-ratio: 2;
      max-height: 350px;
    }

    .titulo {
      height: $tamanho-h3-desktop + $margin-itens;
      margin-bottom: $margin-itens;
    }

    .texto {
      height: $tamanho-p-desktop + $margin-itens/3;
      margin-bottom: $margin-itens/2;
      color: #fff;
    }
  }

  .imgCredenciado {
    .imagem {
      width: 100%;
      object-fit: contain;
      object-position: center;
      max-height: 350px;
    }
  }

  .containerEscrita {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .containerTitulo {
      display: flex;
      color: $cor-primaria;
      align-items: center;
      margin-bottom: $margin-itens;

      .titulo {
        font-size: $tamanho-h3-desktop;
        margin-left: $margin-itens/2;
        text-transform: uppercase;
      }

      .barra {
        width: 45px;
        height: 5px;
        opacity: 100%;
      }
    }

    .texto {
      font-size: $tamanho-p-desktop;
      margin-bottom: $margin-itens;
      color: #fff;
    }

    .link {
      text-decoration: none;

      .botao {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 300px;
        height: 50px;
        background: linear-gradient(
          120deg,
          $cor-botao-terciaria 70%,
          $cor-botao 30%
        );
        color: black;
        font-size: 16px;
        border: none;
        border-radius: 0;

        .seta {
          color: white;
        }
      }

      .botao:hover {
        background: linear-gradient(
          120deg,
          $cor-botao-hover-terciaria 70%,
          $cor-botao 30%
        );
      }

      .botao:active {
        background: linear-gradient(
          120deg,
          $cor-botao-active-terciaria 70%,
          $cor-botao-active 30%
        );
      }

      .botao:focus {
        background-color: $cor-botao-focus;
        border-color: $cor-botao-focus;
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #SecaoCredenciados {
    margin-top: 80px;
    .containerLoading {
      .imagemLoading {
        margin-bottom: $margin-itens;
      }
    }
    .imgCredenciado {
      display: none;
      .imagem {
        padding: 0;
        margin-bottom: $margin-itens;
      }
    }

    .containerEscrita {
      .link {
        .botao {
          width: 100%;
        }
      }
    }
  }
}
