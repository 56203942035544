@import '../../../temas/variaveis';

#SecaoQuemSomos {
  padding: $padding-page 0;

  .loadingTitulo{
    width: 100%;
    height: 40px;
    margin-bottom: $margin-itens;
  }
  .loadingEscrita{
    width: 100%;
    height: 645px;
  }
  .loadingImagem{
    aspect-ratio: 0.8;
    max-height: 720px;
    margin-bottom: $margin-itens;
  }
  .loadingValores{
    width: 100%;
    height: 40px;
    margin-top: $margin-itens/2;
  }
  .loadingListaValores{
    margin: $margin-itens 0;
    height: 630px;
  }

  .containerEscrita {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .tituloSecundario {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $cor-primaria ;
      font-size: 20px;
      margin-bottom: $margin-itens;

      .barra {
        width: 45px;
        height: 5px;
        margin-right: $margin-itens/2;
        opacity: 100%;
      }
    }

    .tituloPrincipal {
      font-size: 30px;
      margin-bottom: $margin-itens;
    }

    .texto {
      font-size: $tamanho-p-desktop;
      margin-bottom: $margin-itens/2;
    }

    .link {
      text-decoration: none;

      .botao {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 50%;
        height: 50px;
        background: linear-gradient(120deg, $cor-botao-terciaria 70%, $cor-botao 30%);
        color: black;
        font-size: 16px;
        border: none;
        border-radius: 0;

        .seta {
          color: white;
        }
      }

      .botao:hover {
        background: linear-gradient(120deg, $cor-botao-hover-terciaria 70%, $cor-botao 30%);
      }

      .botao:active {
        background: linear-gradient(120deg, $cor-botao-active-terciaria 70%, $cor-botao-active 30%);
      }

      .botao:focus {
        background-color: $cor-botao-focus;
        border-color: $cor-botao-focus;
        box-shadow: none;
      }
    }
  }

  .containerImagem {
    position: relative;
    width: 100%;
    max-height: 820px;
    min-height: 500px;
    height: 100%;
    
    &.homeImg {
      max-height: 100%;
      height: 100%;
    }

    .imagem{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .sombra {
      position: absolute;
      width: 35%;
      height: 100%;
      background-color: $cor-primaria;
      top: -$margin-itens;
      bottom: 0;
      left: 70%;
      right: 0;
      z-index: -1;
    }
  }

  .containerValores {
    margin-bottom: $margin-itens;

    .icone {
      margin: $margin-itens 0;
      width: 66px;
      height: 66px;
      object-fit: cover;
      object-position: center;
    }

    .titulo {
      color: $cor-primaria;
      margin-bottom: $margin-itens;
      font-size: 20px;
    }

    .texto {
      font-size: $tamanho-p-desktop;
      margin-bottom: $margin-itens/2;
    }
  }

  .containerListaValoresBotoes {
    .botaoListaValores {
      background-color: white;
      color: black;
      height: 90px;
      width: 100%;
      border: none;
      border-radius: 0;
      text-transform: uppercase;
    }

    .botaoListaValores:active,
    .botaoListaValores:focus,
    .botaoListaValores.ativo {
      background-color: $cor-botao;
      color: white;
      box-shadow: none;
    }

    .barra {
      opacity: 100%;
      color: $cor-primaria;
      margin-top: 5px;
      margin-bottom: $margin-itens;
      height: 2px;
    }
  }

  .valoresListaContainer {
    display: none;

    .containerCarousel {

      min-height: 630px;

      .carousel {
        padding-bottom: $margin-itens;

        .imagemCarousel {
          width: 100%;
          height: 630px;
          object-fit: cover;
          object-position: center;
        }

        .dots {
          button {
            background-color: $cor-secundaria;
            border: none;
          }

          .react-multi-carousel-dot--active {
            button {
              background-color: $cor-primaria;
            }
          }
        }
      }
    }

    .textoValoresLista {
      margin-bottom: $margin-itens;
      font-size: $tamanho-p-desktop;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .valoresListaContainer.ativo {
    display: flex;
  }

}

@media (max-width:991.98px) {
  #SecaoQuemSomos {
    .reverse {
      flex-direction: column-reverse;
    }

    .containerEscrita {
      .link {
        .botao {
          width: 100%;
        }
      }
    }

    .colunaImagem {
      display: flex;
      justify-content: center;
    }

    .containerImagem {
      margin-bottom: $margin-itens;
      width: 100%;
      max-height: 820px;
      min-height: 300px;
      height: 350px;

      &.homeImg {
        height: auto;
      }

      .sombra {
        display: none;
      }
    }
    .valoresListaContainer {
      .containerCarousel {
        min-height: 350px;
        .carousel {
          margin-bottom: $margin-itens;
          .imagemCarousel {
            height: 350px;
          }
        }
      }
    }
  }
}