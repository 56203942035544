@import "../../temas/variaveis";

#SecaoMapaSelecao {
  font-family: "Arial", Tahoma, sans-serif;
  font-size: 12px;

  .estado {
    text-decoration: none;

    .desenho,
    .circle {
      fill: rgb(170, 170, 170);
    }
  }

  .estado.ativo {

    .desenho,
    .circle {
      fill: $cor-primaria;
    }
  }

  .estado.selecionavel {

    .desenho,
    .circle {
      fill: $cor-secundaria;
    }

    &:hover {
      .desenho,
      .circle {
        fill: $cor-botao-hover;
      }
    }
  }

}