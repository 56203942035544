@import "../../../temas/variaveis";

#SecaoServicos {
  padding: $padding-page 0;
  position: relative;

  .fundo {
    background-color: $cor-primaria;
    height: 100%;
    position: absolute;
    width: 40%;
    z-index: -1;
    left: 0;
    top: 0;
  }

  .containerLoading {
    .imagemLoading {
      width: 100%;
      aspect-ratio: 0.8;
    }

    .containerEscritaLoading {
      .tituloLoading {
        height: $tamanho-h3-desktop + $margin-itens;
        margin-bottom: $margin-itens;
      }

      .textoLoading {
        height: $tamanho-p-desktop + $margin-itens/2;
        margin-bottom: $margin-itens/2;
      }

      .cardLoading {
        aspect-ratio: 2.8;
        margin-bottom: $margin-itens;
      }
    }
  }

  .imagemPrincipal {
    width: 100%;
    height: 680px;
    object-fit: cover;
    object-position: center;
  }

  .containerServicos {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .containerTitulo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $cor-primaria;
      margin-bottom: $margin-itens;

      .barra {
        width: 45px;
        height: 5px;
        opacity: 100%;
      }

      .titulo {
        margin-left: $margin-itens/2;
        font-size: $tamanho-h3-desktop;
        text-transform: uppercase;
      }
    }

    .texto {
      font-size: $tamanho-p-desktop;
    }

    .card {
      flex-direction: row;
      border: none;
      border-radius: 0;
      margin-bottom: $margin-itens;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      overflow: hidden;

      .imagemCard {
        border-radius: 0;
        width: 290px;
        height: 220px;
        object-fit: cover;
        object-position: center;
        transition: all 0.3s ease-out 0s;

        &:hover {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }

      .cardBody {
        .titulo {
          color: $cor-primaria;
          text-transform: uppercase;
        }

        .texto {
          font-size: $tamanho-p-mobile;
          overflow: hidden;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; // Quantidade de linhas
          -webkit-box-orient: vertical;
        }

        .link {
          text-decoration: none;

          .botao {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;
            height: 50px;
            background: linear-gradient(
              120deg,
              $cor-botao-terciaria 70%,
              $cor-botao 30%
            );
            color: black;
            font-size: 16px;
            border: none;
            border-radius: 0;

            .seta {
              color: white;
            }
          }

          .botao:hover {
            background: linear-gradient(
              120deg,
              $cor-botao-hover-terciaria 70%,
              $cor-botao 30%
            );
          }

          .botao:active {
            background: linear-gradient(
              120deg,
              $cor-botao-active-terciaria 70%,
              $cor-botao-active 30%
            );
          }

          .botao:focus {
            background-color: $cor-botao-focus;
            border-color: $cor-botao-focus;
            box-shadow: none;
          }
        }
      }
    }
  }

  .carousel {
    display: none;
  }
}

@media (max-width: 991.98px) {
  #SecaoServicos {
    .containerLoading {
      .imagemLoading {
        margin-bottom: $margin-itens;
      }

      .containerEscritaLoading {
        .cardLoading {
          height: 450px;
        }
      }
    }

    .fundo {
      display: none;
    }

    .imagemPrincipal {
      height: 400px;
      margin-bottom: $margin-itens;
    }

    .containerServicos {
      .card {
        flex-direction: column;
        display: none;

        .imagemCard {
          width: 100%;
        }
      }
    }

    .carousel {
      display: flex;
      padding: 0;
      padding-bottom: $margin-itens * 2;

      .dots {
        button {
          background-color: $cor-secundaria;
          border: none;
        }

        .react-multi-carousel-dot--active {
          button {
            background-color: $cor-primaria;
          }
        }
      }

      .card {
        box-shadow: 0px 5px $margin-itens/2 rgba(0, 0, 0, 0.25);
        display: flex;
        margin: 0 $margin-itens/2;
        border: none;
        border-radius: 0;

        .imagemCard {
          width: 100%;
          border-radius: 0;
        }

        .cardBody {
          .titulo {
            color: $cor-primaria;
            text-transform: uppercase;
          }

          .texto {
            font-size: $tamanho-p-desktop;
            min-height: 80px;
            overflow: hidden;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; // Quantidade de linhas
            -webkit-box-orient: vertical;
          }

          .link {
            text-decoration: none;

            .botao {
              display: flex;
              justify-content: space-between;
              align-items: center;

              width: 100%;
              height: 60px;
              background: linear-gradient(
                120deg,
                $cor-botao-terciaria 70%,
                $cor-botao 30%
              );
              color: black;
              font-size: 18px;
              border: none;
              border-radius: 0;

              .seta {
                color: white;
              }
            }

            .botao:hover {
              background: linear-gradient(
                120deg,
                $cor-botao-hover-terciaria 70%,
                $cor-botao 30%
              );
            }

            .botao:active {
              background: linear-gradient(
                120deg,
                $cor-botao-active-terciaria 70%,
                $cor-botao-active 30%
              );
            }

            .botao:focus {
              background-color: $cor-botao-focus;
              border-color: $cor-botao-focus;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
