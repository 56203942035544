@import "../../../temas/variaveis.scss";

#SecaoPostRelacionados {

  .containerLoading {
    .selectLoading {
      height: 100%;
      height: 60px;
      margin-bottom: $margin-itens;
    }

    .containerListagemLoading {
      .cardLoading {
        height: 100px;
        margin-bottom: 10px;
      }
    }
  }

  .containerSelect {
    margin-bottom: $margin-itens;
    .select {
      width: 100%;
      height: 60px;
      padding-left: $margin-itens/2;
      font-weight: bold;
    }
  }

  .containerListagem {
    .link {
      text-decoration: none;
      color: black;

      .card {
        flex-direction: row;
        align-items: center;
        border: none;
        margin: $margin-itens/2 0;

        .imagemCard {
          border-radius: 0;
          width: 130px;
          height: 100px;
          object-fit: cover;
          object-position: center;
        }

        .cardBody {
          padding: 0;
          margin-left: $margin-itens/2;
        }
      }
    }
  }
}