@import '../../../temas/variaveis';

#SecaoAcessoRapido {

  background: linear-gradient(135deg,$cor-primaria 70%, $cor-secundaria 0);
  padding: 5px 0;

  .icone {
    color: white;
  }

  .texto {
    color: white;
  }

  .link {
    text-decoration: none;
  }

  .containerInformacoesEsquerdo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .containerInformacoe {
      display: flex;
      justify-content: center;
      align-items: center;

      .texto {
        margin: 0 $margin-itens /2;
      }
    }
  }

  .containerInformacoesDireito {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .containerInformacoe {
      display: flex;
      justify-content: center;
      align-items: center;

      .icone {
        margin: 0 $margin-itens/2;
      }
    }

    .barra {
      width: 2px;
      height: 20px;
      background-color: white;
      opacity: 100%;
      margin: 0 $margin-itens/2;
    }

    .texto {
      padding: 0 $margin-itens/2;
      margin: 0;
    }
  }

}

@media (max-width: 991.98px) {
  #SecaoAcessoRapido {
    display: none;
  }
}