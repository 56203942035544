@import '../../../temas/variaveis';

#SecaoServico {
  padding-top: 80px;
  padding-bottom: 80px;

  .containerLoading{
    .carouselLoading{
      aspect-ratio: .8;
      margin-bottom: $margin-itens;

    }
    .tituloLoading{
      height: 40px;
      margin-bottom: $margin-itens;
    }
    .textoLoading{
      margin-bottom: $margin-itens/3;
    }
    .especificacaoLoading{
      margin-bottom: $margin-itens/3;
    }
  }

  .coldesc {
    h2 {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .containerCarousel {

      min-height: 630px;

      .carousel {
        padding-bottom: $margin-itens;

        .imagemCarousel {
          width: 100%;
          height: 630px;
          object-fit: cover;
          object-position: center;
        }

        .dot1{
          display: none;
        }
        .dots {
          button {
            background-color: $cor-secundaria;
            border: none;
          }

          .react-multi-carousel-dot--active {
            button {
              background-color: $cor-primaria;
            }
          }
        }
      }
    }
  }

  .coldescp {
    margin-top: 50px;

    .list-group-item {
      padding: unset;
      border: unset;
    }

    span,
    h5 {
      font-weight: 700;
    }

    .usuarioc,
    .reformadorc {
      margin-top: 20px;
    }
  }
}

@media (max-width:991.98px) {
  .coldesc {
    h2 {
      margin-top: 30px;
      font-size: 30px !important;
      text-align: center;
    

    .containerCarousel {
      .carousel {
        margin-bottom: $margin-itens !important;

        .imagemCarousel {
          height: 350px !important;
        }
      }
    }
  }
}
}