// --- Definição das cores ---

$cor-primaria: #ED3237;

$cor-secundaria: #373435;

$cor-terciaria: #F4F7FC;

$cor-quartenaria: gray;

$cor-sombra: #F4F7FC;


$cor-botao: $cor-primaria;
$cor-botao-terciaria: #F4F7FC;

$cor-botao-hover: #962024;
$cor-botao-hover-terciaria: #dde1e6;

$cor-botao-active: #962024;
$cor-botao-active-terciaria: #dde1e6;

$cor-botao-focus: #962024;
$cor-botao-focus-terciaria: #dde1e6;

$cor-placeholder: #6c757d;
$cor-sombra: #ced4da;

$cor-bootstrap-sucesso: #198754;
$cor-bootstrap-erro: #dc3545;

// --- Definições de fontes ---

$tamanho-h1-desktop: 60px;
$tamanho-h2-desktop: 48px;
$tamanho-h3-desktop: 28px;
$tamanho-p-desktop: 18px;

$tamanho-h1-mobile: 40px;
$tamanho-h2-mobile: 35px;
$tamanho-h3-mobile: 20px;
$tamanho-p-mobile: 16px;


$tamanho-pequeno: 16px;
$tamanho-medio: 18px;
// --- Definições de espaçamento ---

$padding-page: 80px;
$padding-page-top: 80px;
$padding-page-bottom: 80px;
$margin-itens: 30px;