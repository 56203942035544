@import "../../../temas/variaveis.scss";

#SecaoPost {
  padding: $padding-page 0;

  .containerLoading{
    .imagemLoading{
      width: 100%;
      aspect-ratio: 1.5;
      margin-bottom: $margin-itens;
    }
    .descricaoLoading{
      height: 24px;
      width: 40%;
      margin-bottom: $margin-itens;
    }
    .tituloLoading{
      height: 46px;
      margin-bottom: $margin-itens;
    }
  } 

  .containerPost {
    .carousel {
      margin-bottom: $margin-itens;

      .imagem {
        width: 100%;
        aspect-ratio: 1.5;
        object-fit: cover;
        object-position: center;
      }

      .dot1 {
        button {
          display: none;
        }
      }

      .react-multi-carousel-dot-list {
        margin: $margin-itens;

        .react-multi-carousel-dot {
          button {
            border: none;
            background-color: $cor-secundaria;
          }
        }

        .react-multi-carousel-dot--active {
          button {
            background-color: $cor-primaria;
          }
        }
      }
    }

    .containerDataCategoria {
      display: flex;
      align-items: center;
      margin-bottom: $margin-itens;

      .categoria {
        background-color: $cor-primaria;
        color: white;
        padding-left: $margin-itens/2;
        padding-right: $margin-itens/2;
        margin-left: $margin-itens;
      }
    }

    .titulo {
      margin-bottom: $margin-itens;
    }
  }
}