@import "../../../temas/variaveis";

#SecaoBlog {

  padding: $padding-page 0;

  .containerLoading {
    .tituloLoading {
      height: $tamanho-h3-desktop + $margin-itens;
      margin-bottom: $margin-itens/2;
    }

    .textoLoading {
      height: $tamanho-p-desktop;
    }

    .selectLoading{
      height: 60px;
    }

    .cardLoading {
      padding: 0 $margin-itens/2;
      margin-top: $margin-itens;
      aspect-ratio: .7;
    }
  }

  .containerTitulo {
    display: flex;
    color: $cor-primaria;
    margin-bottom: $margin-itens;

    .titulo {
      font-size: $tamanho-h3-desktop;
      margin-left: $margin-itens/2;
      text-transform: uppercase;
    }

    .barra {
      width: 45px;
      height: 5px;
      opacity: 100%;
    }
  }

  .containerEscrita {
    margin-bottom: $margin-itens;

    .texto {
      font-size: $tamanho-p-desktop;
    }
  }

  .select{
    width: 100%;
    margin-bottom: $margin-itens;
    height: 50px;
    padding-left: $margin-itens/2;
    font-weight: bold;
  }

  .link {

    text-decoration: none;
    color: black;
    .card {
      border: none;
      border-radius: 0;
      box-shadow: 0px 5px $margin-itens/2 rgba(0, 0, 0, 0.25);
      margin-bottom: $margin-itens;

      .imagem {
        border-radius: 0;
        width: 100%;
        height: 330px;
        object-fit: cover;
        object-position: center;
      }

      .categoria {
        background-color: $cor-primaria;
        color: white;
        display: inline;
        text-align: center;
        font-size: 14px;
        padding: 5px $margin-itens;
      }

      .data {
        background-color: white;
        border: none;
        padding-left: $margin-itens;
        margin-top: $margin-itens;
        font-size: $tamanho-p-desktop;
      }

      .cardBody {

        padding: $margin-itens;
        padding-top: $margin-itens/3;

        .titulo {
          color: $cor-primaria;
          text-transform: uppercase;
          margin-bottom: $margin-itens;
          height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; // Quantidade de linhas
          -webkit-box-orient: vertical;
        }

        .texto {
          font-size: $tamanho-p-desktop;
          height: 112px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4; // Quantidade de linhas
          -webkit-box-orient: vertical;
        }


        .botao {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;
          height: 70px;
          background: linear-gradient(120deg, $cor-botao-terciaria 70%, $cor-botao 30%);
          color: black;
          font-size: 18px;
          border: none;
          border-radius: 0;

          .seta {
            color: white;
          }
        }

        .botao:hover {
          background: linear-gradient(120deg, $cor-botao-hover-terciaria 70%, $cor-botao 30%);
        }

        .botao:active {
          background: linear-gradient(120deg, $cor-botao-active-terciaria 70%, $cor-botao-active 30%);
        }

        .botao:focus {
          background-color: $cor-botao-focus;
          border-color: $cor-botao-focus;
          box-shadow: none;
        }
      }
    }
  }

  .carousel {
    padding-bottom: $margin-itens*2;

    .card {
      margin: 0 $margin-itens/2;
    }

    .dots {
      button {
        background-color: $cor-secundaria;
        border: none;
      }

      .react-multi-carousel-dot--active {
        button {
          background-color: $cor-primaria;
        }
      }
    }
  }
  .containerBotoesTrocaPagina{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: $margin-itens;
    .BotoeTrocaPagina{
      width: 70px;
      height: 70px;
      margin-left: 5px;
      margin-right: 5px;
      background-color: $cor-secundaria;
      color:white;
      font-size: 24px;
      border: none;
    }
    .BotoeTrocaPagina.active{
      background-color: $cor-botao;
    }
  }
}

@media (max-width:991.98px) {
  #SecaoBlog {
    .containerLoading {
      .cardLoading.disabilita {
        display: none;
      }
    }

    .carousel {
      .card {
        .data {
          margin-top: $margin-itens/2;
        }

        .imagem {
          height: 230px;
        }

        .cardBody {
          .titulo {
            margin-bottom: $margin-itens/2;
          }
        }
      }
    }
  }
}