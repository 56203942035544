@import '../../../temas/variaveis';

#SecaoProduto {
  padding-top: 80px;
  padding-bottom: 80px;

  .colCarousel {

    min-height: 330px;

    .carousel {
      padding-bottom: $margin-itens;

      .imagemCarousel {
        cursor: zoom-in;
        width: 100%;
        height: 450px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .carousel-indicators {
    margin-bottom: -10px!important;
  }

  p {
    font-weight: 700;
    color: $cor-primaria;
    margin-bottom: 0;
    font-size: 20px;
  }

  .bi {
    color: $cor-primaria;
  }

  .colInfo {
    hr {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    h2 {
      margin-bottom: 0;
      font-size: 50px;
      font-weight: 700;
      width: 100%;
      padding-bottom: 7px;
      border-bottom: 1px solid gray;
    }

    .prodInfo {
      display: flex;
      margin-bottom: 20px;

      img {
        margin-right: 20px;
        height: 100%;
      }
    }

    .subImagens {
      .subImagem {
        height: 80px;
        padding-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .mobile {
      display: none;
    }

    .prodInfo.icones {
      display: unset;

      img {
        margin-right: 40px;
      }
    }
  }

  .colCaracteristicas {
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;

    .bi {
      font-size: 50px;
      display: flex;
      margin-right: 15px;
    }

    li {
      font-weight: 300;

      p {
        color: $cor-secundaria;
        font-weight: normal;
      }
    }
  }

  .colArq {
    .arquivoLink {
      margin-bottom: 10px;
    }

    a {
      all: unset;
      cursor: pointer;

      span {
        margin-left: 20px;
      }
    }

    h5 {
      font-size: 30px;
      font-weight: 700;
    }

    .bi {
      font-size: 30px;
    }

    hr {
      color: $cor-primaria;
      opacity: 100%;
      display: none;
    }
  }
}

@media (max-width:991.98px) {
  #SecaoProduto {
    .colInfo {
      flex-direction: column;
      display: flex;
      justify-content: center;
      // align-items: center;

      &>* {
        margin-top: 20px;
      }

      h2 {
        text-align: center;
      }

      .prodInfo.icones {
        img {
          display: flex;
          width: 150px;
          margin: auto;
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }

      .desktop {
        display: none
      }

      .mobile {
        display: flex;

        button {
          background-color: $cor-secundaria;
          border: none;
          width: 10px;
          height: 10px;
          border-radius: 10px;
        }

        button.active {
          background-color: $cor-primaria;
        }

        .carousel-control-next-icon, .carousel-control-prev-icon {
          display: none !important;
        }

      }
    }

    .colCaracteristicas {
      padding-top: 20px;
      padding-bottom: 40px;

      .bi {
        display: none;
      }
    }

    .colArq {
      text-align: center;
    }
  }
}

@media (min-width: 991.99px) {
  #SecaoProduto {
    .colInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 30px
    }

    .subImagens {
      .subImagem {
        padding-right: 0;
      }
    }
  }
}

// --------- styles skeletons --------


#SecaoProduto {
  .skeletonImagemProduto {
    height: 330px;
    width: 296px;
  }

  .skeletonTituloProduto {
    height: 68px;
    width: 100%;
  }

  .skeletonCaracteristicasProduto {
    height: 260px;
    width: 100%;
    padding-top: 20px;
  }

  .skeletonInfoProduto {
    height: 306px;
    width: 100%;
  }

  .skeletonArquivoProduto {
    height: 90px;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  #SecaoProduto {
    .skeletonImagemProduto {
      width: 100%;
    }
  }
}