@import "../../../temas/variaveis";

#SecaoListagemServicos {
  padding: $padding-page 0;
  position: relative;

  .fundo {
    position: absolute;
    width: 100%;
    background: linear-gradient(135deg, $cor-primaria 40%, $cor-secundaria 30%);
    height: 350px;
    z-index: -1;
    top: 0;
  }

  .containerLoading {
    .loadingTitulo {
      width: 100%;
      height: $tamanho-h3-desktop + ($tamanho-p-desktop*7) + $margin-itens;
      margin-bottom: $margin-itens;
    }

    .loadingCard {
      width: 100%;
      height: 550px;
    }
  }

  .containerEscrita {
    margin-bottom: $margin-itens;
    color: white;

    .containerTitulo {
      display: flex;
      align-items: center;

      .barra {
        width: 45px;
        height: 5px;
        opacity: 100%;
      }

      .titulo {
        font-size: $tamanho-h3-desktop;
        margin-left: $margin-itens/2;
        text-transform: uppercase;
      }

      .texto {
        font-size: $tamanho-p-desktop;
      }
    }
  }

  .card {
    border: none;
    border-radius: 0;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

    .imagem {
      border-radius: 0;
      width: 100%;
      height: 330px;
      object-fit: cover;
      object-position: center;
    }

    .cardBody {

      padding: $margin-itens;

      .titulo {
        color: $cor-primaria;
        text-transform: uppercase;
      }

      .texto {
        font-size: $tamanho-p-desktop;
        height: 140px;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; // Quantidade de linhas
        -webkit-box-orient: vertical;
      }

      .link {
        text-decoration: none;

        .botao {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;
          height: 70px;
          background: linear-gradient(120deg, $cor-botao-terciaria 70%, $cor-botao 30%);
          color: black;
          font-size: 18px;
          border: none;
          border-radius: 0;

          .seta {
            color: white;
          }
        }

        .botao:hover {
          background: linear-gradient(120deg, $cor-botao-hover-terciaria 70%, $cor-botao 30%);
        }

        .botao:active {
          background: linear-gradient(120deg, $cor-botao-active-terciaria 70%, $cor-botao-active 30%);
        }

        .botao:focus {
          background-color: $cor-botao-focus;
          border-color: $cor-botao-focus;
          box-shadow: none;
        }
      }
    }
  }

}

@media (max-width: 991.98px) {
  #SecaoListagemServicos {
    .fundo {
      display: none;
    }

    .containerLoading {
      .loadingCard {
        margin-bottom: $margin-itens;
      }
    }

    .containerEscrita {
      color: $cor-primaria;

      .containerTitulo {
        margin-bottom: $margin-itens;
      }

      .texto {
        color: black;
      }
    }

    .card {
      margin-bottom: $margin-itens;
    }
  }
}