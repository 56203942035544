@import "../../../temas/variaveis";

#SecaoFormulario {
  padding: 80px 0;

  .texto {
    font-size: $tamanho-p-desktop;
    color: $cor-primaria;
    margin-bottom: 20px;
  }

  .boxEsquerdo {
    // display: flex;
    // flex-direction: column;
    //justify-content: space-between;
  }

  .form-control {
    font-size: $tamanho-p-desktop;
    line-height: 2;
    border-radius: 0;
    margin-bottom: 1rem !important;
  }

  .itemBotao {
    // display: block;
    // text-align: -webkit-right;

    display: flex;
    justify-content: flex-end;

    .botao {
      padding-left: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      background: linear-gradient(
        120deg,
        $cor-botao-terciaria 80%,
        $cor-botao 30%
      );
      color: black;
      font-size: $tamanho-p-desktop;
      border: none;
      border-radius: 0;

      .seta {
        color: white;
      }
    }

    .botao:hover {
      background: linear-gradient(
        120deg,
        $cor-botao-hover-terciaria 80%,
        $cor-botao 30%
      );
    }

    .botao:active {
      background: linear-gradient(
        120deg,
        $cor-botao-active-terciaria 80%,
        $cor-botao-active 30%
      );
    }

    .botao:focus {
      background-color: $cor-botao-focus;
      border-color: $cor-botao-focus;
      box-shadow: none;
    }

    .botaoForms {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 40%;
      height: 70px;
      background: linear-gradient(
        120deg,
        $cor-botao-terciaria 70%,
        $cor-botao 30%
      );
      color: black;
      font-size: $tamanho-p-desktop;
      border: none;
      border-radius: 0;

      .seta {
        color: white;
      }
    }

    .botaoForms:hover {
      background: linear-gradient(
        120deg,
        $cor-botao-hover-terciaria 70%,
        $cor-botao 30%
      );
    }

    .botaoForms:active {
      background: linear-gradient(
        120deg,
        $cor-botao-active-terciaria 70%,
        $cor-botao-active 30%
      );
    }

    .botaoForms:focus {
      background-color: $cor-botao-focus;
      border-color: $cor-botao-focus;
      box-shadow: none;
    }
  }

  .boxDireita {
    position: relative;

    .imgTrabalheConosco {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .infoForms {
      position: absolute;
      left: 80px;
      right: 80px;
      bottom: -90px;
      background-color: white;
      padding: 30px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

      .icone {
        margin-top: 8px;
        color: $cor-primaria;
      }

      .itemTexto {
        padding: 0 0px 0 30px;

        h3 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
          margin-top: 5px;
        }

        p {
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }
  }

  .input-files-ftc-group {
    input {
      display: none;
    }

    label {
      width: 100%;
      text-align: center;
      cursor: pointer;
      border: 1px solid $cor-sombra;
      margin-right: 1rem;
      color: $cor-placeholder;
      padding: 6px 12px;
      line-height: 2;
      font-size: 13px;
    }

    .input-span-box {
      display: flex;
      span {
        color: $cor-placeholder;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &.invalid {
          color: $cor-bootstrap-erro;
        }

        &.valid {
          color: $cor-bootstrap-sucesso;
        }
      }
    }
  }
}

@media (min-width: 991.99px) {
  .pais,
  .estado {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .ReCaptcha div div {
    display: flex;
    justify-content: center;
  }

  #SecaoFormulario {
    .botao {
      width: 100% !important;
    }

    .botaoForms {
      width: 100% !important;
    }

    .boxDireita {
      position: inherit;

      .imgTrabalheConosco {
        padding: 20px 0;
      }

      .infoForms {
        position: inherit;
        padding: 30px;

        .itemTexto {
          padding: 0 10px;

          h3 {
            margin-top: 7px;
          }
        }
      }
    }
  }
}
