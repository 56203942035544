@import "../../../temas/variaveis";

#SecaoBanner {
  .loadingBannerPrincipal {
    height: 33vw;
  }

  .loadingBannerInterno {
    height: 22vw;
  }

  .containerBanner {
    padding: 0;
    position: relative;

    .containerTitulo {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: white;

      .tituloEscrita {
        font-size: $tamanho-h1-desktop;
      }
    }

    .containerCaminhoRaiz {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .caminhoRaizLink {
        color: white;
        text-decoration: none;
        .escritaRaizLink {
          margin-right: 5px;
        }
      }
    }

    .carrosel {
      .imagemBanner {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .imagemBannerPrincipal {
        height: 33vw;
        filter: brightness(80%);
      }

      .imagemBannerInterno {
        height: 22vw;
        object-position: center;
        filter: brightness(50%);
      }

      .dot1 {
        button {
          display: none;
        }
      }
      .react-multi-carousel-dot-list {
        margin: $margin-itens;

        .react-multi-carousel-dot {
          button {
            border: none;
            background-color: $cor-secundaria;
          }
        }

        .react-multi-carousel-dot--active {
          button {
            background-color: $cor-primaria;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #SecaoBanner {
    .loadingBannerPrincipal {
      height: 50vw;
    }

    .loadingBannerInterno {
      height: 200px;
    }
    .react-multi-carousel-dot-list {
      margin: 0!important;
    }

    .containerBanner {
      .containerTitulo {
        .tituloEscrita {
          font-size: $tamanho-h1-mobile;
        }
      }

      .carrosel {
        .imagemBanner {
          height: 50vw;
        }
      }
    }
  }
}
