@import "../../../temas/variaveis";

#SecaoProdutosRelacionados {
  padding: $padding-page 0;

  .containerLoading{
    .tituloLoading{
      height: 50px;
      margin-bottom: $margin-itens;
    }
    .cardLoading{
      padding: 0 $margin-itens;
      aspect-ratio: 0.8;
    }
  }

  .containerTitulo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $cor-primaria;
    margin-bottom: $margin-itens;

    .barra {
      opacity: 100%;
      width: 45px;
      height: 5px;
    }

    .titulo {
      margin-left: $margin-itens/2;
    }
  }

  .containerCarousel {
    .carousel {
      padding: $margin-itens 0;
      .dot1,.dot2,.dot3,.dot4{
        display: none;
      }

      .dots {
        button {
          background-color: $cor-secundaria;
          border: none;
        }
        
        .react-multi-carousel-dot--active {
          button {
            background-color: $cor-primaria;
          }
        }
      }

      .link {
        text-decoration: none;
        color: black;

        .card {
          margin: 0 $margin-itens/2;
          border: none;
          border-radius: 0;
          box-shadow: 0px 5px $margin-itens/2 rgba(0, 0, 0, 0.25);

          .imagem {
            border-radius: 0;
            width: 100%;
            height: 280px;
            object-fit: contain;
            object-position: center;
          }

          .titulo {
            color: $cor-primaria;
            text-transform: uppercase;
          }

          .botao {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;
            height: 70px;
            background: linear-gradient(120deg, $cor-botao-terciaria 70%, $cor-botao 30%);
            color: black;
            font-size: 18px;
            border: none;
            border-radius: 0;

            .seta {
              color: white;
            }
          }

          .botao:hover {
            background: linear-gradient(120deg, $cor-botao-hover-terciaria 70%, $cor-botao 30%);
          }

          .botao:active {
            background: linear-gradient(120deg, $cor-botao-active-terciaria 70%, $cor-botao-active 30%);
          }

          .botao:focus {
            background-color: $cor-botao-focus;
            border-color: $cor-botao-focus;
            box-shadow: none;
          }
        }
      }
    }
  }
}