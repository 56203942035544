@import "../../../temas/variaveis";

#SecaoListagemProdutos {
  padding-bottom: $padding-page-bottom;
  padding-top: $padding-page-top;
  .accordion {
    display: none;
  }
  .categoriaInfo {
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    .img {
      height: 300px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .descricao {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;

      .titulo {
        color: $cor-secundaria;
        font-size: $tamanho-h1-mobile;
        margin-bottom: 1rem;
        font-weight: bold;
      }

      .texto {
        font-size: $tamanho-medio;
        color: $cor-secundaria;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }

  .produtosListagem {
    .naoEncontrado {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $tamanho-h3-mobile;
      font-weight: bold;
      color: $cor-quartenaria;
    }
    .menu,
    .listagem {
      height: 100%;
    }
    .listagem {
      padding: 0;
      .cardContainer {
        padding-bottom: 20px;

        .linkOff {
          height: 110px;
        }

        .link {
          text-decoration: none;
        }
        
        .card {
          background-color: transparent;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          transition: all 0.3s ease-out 0s;
          &:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
          }

          .titulo {
            color: $cor-primaria;
            font-size: $tamanho-h3-mobile;
            font-weight: bold;
          }

          .imagem {
            height: 280px;
            object-fit: contain;
            object-position: center;
          }

          .cardBody {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 160px;
          }

          .botao {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
            background: linear-gradient(120deg, #f4f7fc 70%, #ed3237 30%);
            color: black;
            font-size: $tamanho-pequeno;
            text-decoration: none;
            border: none;
            border-radius: 0;

            .seta {
              fill: white;
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }

    .colMenu {
      margin-bottom: 15px;
      .menu {
        padding-bottom: 15px;

        input[type="checkbox"] {
          cursor: pointer;
          accent-color: $cor-primaria;
        }

        &.comLinha {
          border-bottom: 1px solid black;
        }

        .titulo {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .option {
          margin-top: 5px;
          display: flex;
          input {
            margin-right: 5px;
          }
          label {
            cursor: pointer;
            text-transform: uppercase;
            text-transform: capitalize;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #SecaoListagemProdutos {
    .filtro {
      display: none;
    }
    .accordion {
      display: block;
      margin-bottom: 30px;
    }
    .categoriaInfo {
      .titulo {
        margin-top: 1rem;
      }
    }
  }
}

// ------------------- skeletons styles --------------------

#SecaoListagemProdutos {
  .skeletonCategoria {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
  }

  .skeletonCategoriaInfo {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
  }

  .skeletonCard {
    height: 400px;
    padding-bottom: 15px;
  }

  .skeletonMenu {
    height: 120px;
    margin-bottom: 15px;
  }

  @media (min-width: 991.99px) {
    .skeletonCategoriaInfo {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    .skeletonCategoria {
      margin-bottom: 15px;
    }
  }
}
