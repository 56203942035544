
@import '../../temas/variaveis';

section#seletores {
  display: flex;
  padding: 25px 50px 0;
  justify-content: flex-start;

  .ibtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    color: $cor-terciaria;
    background-color: $cor-secundaria;
    margin: 0 0.2rem;
    cursor: pointer;

    // &:active {
    //   background-color: $second-color;
    // }

    &.selected {
      background-color: $cor-primaria;
    }
  }

  .separator {
    background-color: transparent;
    width: 30px;
  }
}

@media (max-width:991.98px) {
  section#seletores {
    padding: 40px 0 !important;

    justify-content: center;

    .ibtn {
      width: 2.7rem !important;
      height: 2.7rem !important;
    }

    .separator {
      width: 2rem !important;
    }
  }
}