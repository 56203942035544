@import "../../../temas/variaveis";

#SecaoCategoriasProduto {
  padding: $padding-page 0;
  position: relative;

  .fundo {
    position: absolute;
    width: 100%;
    height: 350px;
    background: linear-gradient(135deg, $cor-primaria 40%, $cor-secundaria 30%);
    z-index: -1;
    top: 0;
  }

  .containerLoading {
    .loadingTitulo {
      width: 100%;
      height: $tamanho-h3-desktop + ($tamanho-p-desktop * 7) + $margin-itens;
      margin-bottom: $margin-itens;
    }

    .loadingCard {
      width: 100%;
      height: 550px;
    }
  }

  .containerTitulo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    margin-bottom: $margin-itens 0;

    .barra {
      height: 5px;
      width: 45px;
      opacity: 100%;
    }

    .titulo {
      margin-left: $margin-itens/2;
      font-size: $tamanho-h3-desktop;
    }

    .escrita {
      margin-left: $margin-itens;
      font-size: $tamanho-p-desktop;

      p {
        margin-bottom: 30px;
      }
    }
  }

  .card {
    border: none;
    border-radius: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin-bottom: $margin-itens;
    overflow: hidden;
  }

  .imagem {
    border-radius: 0;
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    background-color: #efefefef;
    transition: all 0.3s ease-out 0s;

    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .cardBody {
    padding: $margin-itens;

    .titulo {
      text-transform: uppercase;
      font-size: $tamanho-h3-mobile;
      color: $cor-primaria;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      line-height: $tamanho-h3-mobile;
      height: calc($tamanho-h3-mobile * 2);
      font-weight: bold;
      letter-spacing: 1px;
    }

    .texto {
      font-size: $tamanho-pequeno;
      color: $cor-secundaria;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      line-height: $tamanho-pequeno;
      height: calc($tamanho-pequeno * 4);
    }

    .link {
      text-decoration: none;

      .botao {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        height: 50px;
        background: linear-gradient(120deg,
            $cor-botao-terciaria 70%,
            $cor-botao 30%);
        color: black;
        font-size: 16px;
        border: none;
        border-radius: 0;

        .seta {
          color: white;
        }
      }

      .botao:hover {
        background: linear-gradient(120deg,
            $cor-botao-hover-terciaria 70%,
            $cor-botao 30%);
      }

      .botao:active {
        background: linear-gradient(120deg,
            $cor-botao-active-terciaria 70%,
            $cor-botao-active 30%);
      }

      .botao:focus {
        background-color: $cor-botao-focus;
        border-color: $cor-botao-focus;
        box-shadow: none;
      }
    }
  }

  .carousel {
    padding-bottom: $margin-itens * 2;

    .card {
      margin: 0 $margin-itens/2;
    }

    .dots {
      button {
        background-color: $cor-secundaria;
        border: none;
      }

      .react-multi-carousel-dot--active {
        button {
          background-color: $cor-primaria;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #SecaoCategoriasProduto {
    .fundo {
      display: none;
    }

    .containerLoading {
      .loadingCard {
        margin-bottom: $margin-itens;
      }

      .loadingCard.disabilita {
        display: none;
      }
    }

    .containerTitulo {
      color: $cor-primaria;

      .escrita {
        color: black;
        margin-left: 0;
        margin-top: $margin-itens;
        font-size: $tamanho-p-desktop;
      }
    }

    .carousel {
      .card {
        .imagem {
          height: 230px;
        }

        .texto {
          font-size: $tamanho-pequeno;
          color: $cor-secundaria;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          /* number of lines to show */
          -webkit-box-orient: vertical;
          line-height: $tamanho-pequeno;
          height: calc($tamanho-pequeno * 4);
        }
      }
    }
  }
}

@media (min-width: 991.99px) {
  #SecaoCategoriasProduto {
    .carousel {
      .dots {
        display: none;
      }
    }
  }
}